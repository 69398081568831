import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import gel from 'aesop-gel';

import { Paper, Grid, Typography, Link, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AesopLogo from '../Component/AesopLogo';

const { Button, Loading } = gel.components;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
    maxWidth: 'none',
    minHeight: 57,
  },
}));

const ResetPassword = ({ setScreen }) => {
  const [email, setEmail] = useState('');
  const [stage, setStage] = useState('email');

  const [resetCode, setResetCode] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [loading, setLoading] = useState(false);


  const [error, setError] = useState();

  const requestResetCode = async (e) => {
    e.preventDefault();
    if (!email) {
      setError('please enter an email');
      return;
    }
    Auth.forgotPassword(email);
    setStage('code');
  }


  const updatePassword = async (e) => {
    e.preventDefault();

    if (newPass !== confirmPass) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);
      Auth.forgotPasswordSubmit(email, resetCode, newPass)
      .then((status) => {
        alert('Your password has now been reset, please sign in');
        setScreen('signin');
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (typeof e === 'object') {
          setError(e.message);
        } else {
          setError(e.toString().replace('AuthError: ', ''));
        }
      });
  }

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <AesopLogo width={300} />
        <form className={classes.form} noValidate>
          {stage === 'email' ? (
            <>
              <Typography variant="body1">
                Please enter your email and a password reset code will be emailed to you
              </Typography>
              <Typography variant="body1" color="error">
                {error}
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Grid>
                <Button
                  type="submit"
                  title="update password"
                  fullWidth
                  isAlternate
                  isEnabled={!loading}
                  disabled={loading}
                  className={classes.submit}
                  onClick={requestResetCode}
                >
                  {loading ? <Loading isLoading={true} theme="light" /> : 'Reset Password'}
                </Button>
                <Link onClick={() => setScreen('signin')}>Back</Link>
              </Grid>
            </>
          ) : (
            <>
              <Typography variant="body1">
                A password reset link will has been emailed to you
              </Typography>
              <Typography variant="body1" color="error">
                {error}
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Reset code"
                id="resetcode"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confrim New Password"
                type="password"
                id="password"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
              />
              <Grid>
                <Button
                  type="submit"
                  title="update password"
                  fullWidth
                  isAlternate
                  isEnabled={!loading}
                  disabled={loading}
                  className={classes.submit}
                  onClick={updatePassword}
                >
                  {loading ? <Loading isLoading={true} theme="light" /> : 'Update Password'}
                </Button>
              </Grid>
            </>
          )}
        </form>
      </div>
    </Grid>
  );
}

export default ResetPassword;
