import React from 'react';
import { ReactComponent as Logo } from '../logo.svg';

const Header = ()=>(
    <header>
        <Logo />
        <h1>URL Shortening Generator</h1>
  </header>
);


export default Header;