import React, { useState, useEffect } from 'react';
import { print } from 'graphql/language/printer';
import { loader } from 'graphql.macro';

import gel from 'aesop-gel';
import TextField from './TextField';

const GET_STORES = loader('./GET_STORES.graphql');

const { FlyinPanel, Button } = gel.components;

const LOCALE_MAP = {
  'us': 'en-US',
  'au': 'en-AU',
  'nz': 'en-NZ',
  'sg': 'en-SG',
  'uk': 'en-GB',
  'fr/fr': 'fr-FR',
  'fr/en': 'en-FR',
  'ca/en': 'en-CA',
  'ca/fr': 'fr-CA',
  'hk/en': 'en-HK',
  'hk/zh_cn': 'zh-Hans-HK',
  'tw': 'zh-Hant-TW',
  'hk/zh_hant': 'zh-Hant-HK',
  'de/de': 'de-DE',
  'de/en': 'en-DE',
  'jp': 'ja-JP',
  'kr': 'ko-KR',
  'it': 'it-IT',
  'dk': 'en-DK',
  'se': 'en-SE',
  'no': 'en-NO',
  'ch/de': 'de-CH',
  'ch/fr': 'fr-CH',
  'ch/en': 'en-CH',
};


const fetchGraphQL = (query, country) => {
  /* eslint-disable camelcase */
  let parsedQuery = query;
  // If the query is a GraphQL AST (i.e imported from a .graphql file) print it to a string
  if (typeof query === 'object') {
    parsedQuery = print(query);
  }

  return fetch('https://www.aesop.com/graphql', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-api-key': 'unPGMTFfsi1fyT9Fi03Ee6Ds5LMhatBh7LZtQ7o8',
      'x-locale': LOCALE_MAP[country],
    },
    body: JSON.stringify({ query: parsedQuery }),
  }).then(resp => resp.json())
    .catch(console.error);
  /* eslint-enable camelcase */
};

const CnCSelector = ({ selectedStore, setSelectedStore, country }) => {
  const [showModal, setShowModal] = useState(false);
  const [storesLoading, setStoresLoading] = useState(false);
  const [storeResults, setStoreResults] = useState([]);

  useEffect(() => {
    setSelectedStore(null);
    setStoresLoading(true);
    fetchGraphQL(GET_STORES, country)
      .then(res => {
        setStoreResults(res.data.stores);
        setStoresLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setStoresLoading(false);
      });
  }, [country, setSelectedStore])


  const handleStoreClick = (store) => {
    setSelectedStore(store);
    setShowModal(false);
  }

  return (
  <div style={{ display: 'flex', flexDirection: 'column',  marginTop: 20}}>
    <FlyinPanel
      isVisible={showModal}
      onClose={() => setShowModal(false)}
    >
        <div className="store-list">
          {!storesLoading && storeResults.map((store) => (
            <Button
              isInline
              onClick={() => handleStoreClick(store)}
              isEnabled={store.hasCnC}
            >
              <strong>{store.name}</strong><br/>
              {store.address}
            </Button>
          ))}
          {storesLoading && <span>Loading Stores</span>}
        </div>
    </FlyinPanel>
      <label>Click and Collect</label>
      {selectedStore ? (
        <Button
          isInline
          className="store-button"
          onClick={() => setShowModal(true)}
        >
          {selectedStore.name} - click to change
        </Button>
      ) : (
        <Button
          className="select-store-button"
          onClick={() => setShowModal(true)}
        >
          Select Store
        </Button>
      )}
  </div>
  );
}


export default CnCSelector;
