import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import Link from '../Component/Link';
import {
  Typography,
  Breadcrumbs,
  Paper,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  Button,
  Chip,
  TableCell,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import EditGroupModal from './UserManager/EditGroupModal';
import EditAttributeModal from './UserManager/EditAttributeModal';

const GET_USER = loader('../Utils/Queries/GET_USER.graphql');
const DELETE_USER = loader('../Utils/Mutation/DELETE_USER.graphql');

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(4),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  }
}));

const ListUsers = () => {
  const { userId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [editAttribute, setEditAttribute] = useState(false);
  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      userId,
    },
  });

  const [deleteUser] = useMutation(DELETE_USER);

  const handleDeleteUser = async () => {
    // eslint-disable-next-line no-restricted-globals
    const check = confirm('Are you sure you want to delete this user');
    if (check) {
      await deleteUser({
        variables: {
          userId
        },
      });
      alert('User Deleted');
      history.push('/manage-users');
    }
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Home
        </Link>
        <Link color="inherit" href="/manage-users">
          Users
        </Link>
        <Typography color="textPrimary">{userId}</Typography>
      </Breadcrumbs>
        <Typography variant="h3" gutterBottom>
          Manage user
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">Email</TableCell>
                <TableCell align="right">{data.user.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Status</TableCell>
                <TableCell align="right">{data.user.status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Groups</TableCell>
                <TableCell align="right">
                  {data.user.groups.map(group => (
                    <Chip key={group.id} label={group.description} className={classes.chip} />
                  ))}
                  <Button onClick={() => setShowModal(true)}>Edit</Button>
                </TableCell>
              </TableRow>
              {data.user.groups.some(g => g.description === 'Warehouse' || g.description === 'Admin') && (
              <>
                <TableRow>
                  <TableCell component="th" scope="row">Warehouse Providers</TableCell>
                  <TableCell align="right">
                    {data.user.warehouse_providers && data.user.warehouse_providers.map((provider) => (
                      <Chip key={provider} label={provider} className={classes.chip} />
                    ))}
                    <Button onClick={() => setEditAttribute('warehouse_providers')}>Edit</Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Warehouse Region</TableCell>
                  <TableCell align="right">
                    {data.user.warehouse_region}
                    <Button onClick={() => setEditAttribute('warehouse_region')}>Edit</Button>
                  </TableCell>
                </TableRow>
              </>
              )}
            </TableBody>
          </Table>
      </TableContainer>
      {editAttribute && <EditAttributeModal user={data.user} attribute={editAttribute} open={!!editAttribute} setOpen={setEditAttribute} />}
      <EditGroupModal userId={data.user.id} groups={data.user.groups.map(g => g.id)} open={showModal} setOpen={setShowModal} />
      <Button onClick={handleDeleteUser}>Delete User</Button>
    </Container>
  );
}

export default ListUsers;
