import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Chip,
  CircularProgress,
} from '@material-ui/core';

const MODIFY_ATTRIBUTE = loader('../../Utils/Mutation/MODIFY_ATTRIBUTE.graphql');

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  }
}));


const EditGroupModal = ({ open, setOpen, user, attribute }) => {
  const initialValue = Array.isArray(user[attribute]) ? user[attribute].join(',') : user[attribute];
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);
  const [saving, setSaving] = useState(false);
  const [modifyAttribute] = useMutation(MODIFY_ATTRIBUTE)

  console.log(attribute, initialValue);

  const updateGroups = async () => {
    setSaving(true);
    await modifyAttribute({
      variables: {
        userId: user.id,
        attributeKey: attribute,
        attributeValue: value,
      }
    });
    setOpen(false);
  }

  return (
    <Dialog className={classes.dialog} onClose={() => setOpen(false)} open={open}>
      <DialogTitle>Edit Groups</DialogTitle>
      <DialogContent>
      <DialogContentText>
        Enter the new value (comma separated for multiple)
      </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={attribute}
          type="text"
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={updateGroups}>{saving ? <CircularProgress /> : 'Save'}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditGroupModal;
