import React from 'react';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import FolderIcon from '@material-ui/icons/Folder';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import UserCard from '../Component/UserCard';


const Home = ({ user, actions }) => {
  const router = useHistory();

  return (
    <Container>
      <UserCard user={user} />
      <List>
        {actions.map(action => (
          <ListItem button onClick={() => router.push(action.path)}>
            <ListItemAvatar><Avatar><FolderIcon /></Avatar></ListItemAvatar>
            <ListItemText
              primary={action.title}
              secondary={action.subtitle}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default Home;
