import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import gel from 'aesop-gel';
const { Button } = gel.components;

const GENERATE_SHORT_URL = loader('../GENERATE_SHORT_URL.graphql');


const GeneratedUrl = ({ redirect }) => {
    const [redirectValue, setRedirectValue] = useState('');

    const [mutate, { loading, error, data }] = useMutation(GENERATE_SHORT_URL, {
        onCompleted: (res) => setRedirectValue(res.generateShortURL.URL),
    });

    if (loading) return <div className="GeneratedUrl">Loading</div>
    if (error) return <div className="GeneratedUrl">Error: Please Refresh</div>

    return (
        <div className="GeneratedUrl">
            <div className="UrlBox">
                {redirectValue}
            </div>
            <Button isAlternate className="centered-button" onClick={(e) => {
                        e.preventDefault();
                        mutate({variables: { redirectURL: redirect } });
            }}>
                Generate URL
            </Button>
        </div>
    );
};

export default GeneratedUrl;
