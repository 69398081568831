import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import gel from 'aesop-gel';

import { Paper, Grid, Typography, CssBaseline, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AesopLogo from '../Component/AesopLogo';

const { Button, Loading } = gel.components;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
    maxWidth: 'none',
    minHeight: 57,
  },
}));

const UpdatePassword = ({ tempUser, setUserState }) => {
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState();

  const updatePassword = async (e) => {
    e.preventDefault();

    if (newPass !== confirmPass) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);
    Auth.completeNewPassword(tempUser, newPass)
      .then((user) => {
        console.log('success', user);
        setUserState(user);
        setLoading(false);
        return user;
      })
      .catch((e) => {
        setLoading(false);
        if (typeof e === 'object') {
          setError(e.message);
        } else {
          setError(e.toString().replace('AuthError: ', ''));
        }
      });
  }

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <AesopLogo width={300} />
        <form className={classes.form} noValidate>
          <Typography variant="body1">
            Please set a new password for yourself
          </Typography>
          <Typography variant="body1" color="error">
            {error}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confrim New Password"
            type="password"
            id="password"
            value={confirmPass}
            onChange={(e) => setConfirmPass(e.target.value)}
          />
          <Grid>
            <Button
              type="submit"
              title="update password"
              fullWidth
              isAlternate
              isEnabled={!loading}
              disabled={loading}
              className={classes.submit}
              onClick={updatePassword}
            >
              {loading ? <Loading isLoading={true} theme="light" /> : 'Update Password'}
            </Button>
          </Grid>
        </form>
      </div>
    </Grid>
  );
}

export default UpdatePassword;
