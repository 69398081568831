import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import styled from 'styled-components';

import { Container, Typography, Breadcrumbs, Button, TextField, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';


import Link from '../Component/Link';
import UserTable from '../Component/UserTable';

const GET_USERS = loader('../Utils/Queries/GET_USERS.graphql');

const SearchBox = styled.form`
  display: flex;
  align-items: center;
  width: 600px;

  p {
    margin-right: 20px;
  }
`;

const ListUsers = () => {
  const [email, setEmail] = useState();
  const [searchUsers, { loading, error, data }] = useLazyQuery(GET_USERS, {
    variables: {
      email,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    searchUsers({ variables: {email} })
  }
  console.log(loading, error, data)

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">Users</Typography>
      </Breadcrumbs>
      <SearchBox onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Search Users by Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* <IconButton onClick={() => searchUsers({ variables: {email} })} type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton> */}
      </SearchBox>

      {loading && (
        <p>Loading...</p>
      )}
      {(data?.users.length && !loading) && (
        <UserTable users={data.users} />
      )}
      {(data && !data.users.length && !loading) && (
        <p>No users found</p>
      )}
      <Button component={RouterLink} href="/manage-users/add-user" to="/manage-users/add-user">Add new user</Button>
    </Container>
  );
}

export default ListUsers;
