import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import Layout from './Layout/Layout';
import NotFound from './Page/404';
import OrderExport from './Page/OrderExport';
import UserManager from './Page/UserManager';
import BastionAccess from './Page/BastionAccess';
import Home from './Page/Home';
import ChangePassword from './Page/ChangePassword';
import ExternalOrders from './Page/ExternalOrders';

import CartBuilder from './SubApps/CartBuilder/App';
import URLShortener from './SubApps/URLShortener/App';
import ImageOptimize from './Component/ImageOptimize';

const GET_SELF = loader('./Utils/Queries/GET_SELF.graphql');

const RouteComponents = {
  OrderExport,
  UserManager,
  CartBuilder,
  BastionAccess,
  URLShortener,
  ImageOptimize,
  ExternalOrders,
};

const Analytics = () => {
  const history = useHistory();

  useEffect(() => {
    return history && history.listen((location) => {
      window.gtag('set', 'page', location.pathname + location.search);
      window.gtag('send', 'pageview');
    });
  }, [history])

  return null;
}

export default function AppRouter(props) {
  const { loading, error, data } = useQuery(GET_SELF);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        Please wait...
      </div>
    );
  }
  if (error) {
    return 'error';
  }

  const actions = data.user.groups.reduce((actions, group) => {
    // Filter out double actions, i.e admin role + another role will provide a duplicate action
    return [...actions, ...group.actions.filter(a => !actions.some(an => a.id === an.id))];
  }, []);

  const DRAWER_LINKS = [
    { text: "Home", path: "/", icon: <InboxIcon /> },
    ...(actions.map((action) => ({ text: action.title, path: action.path, icon: <InboxIcon /> }))),
    { text: "Change Password", path: "/change-password", icon: <InboxIcon /> },
  ];

  const {signoutButton} = props;
  return (
    <Router>
      <Analytics />
      <Switch>
        {actions.map((route) => {
          const Component = RouteComponents[route.component];
          if (data.type === 'subApp') {
            return <Route exact path={route.path} component={Component} />
          }
          return (
            <Route path={route.path}>
              <Layout title={route.title} drawerLinks={DRAWER_LINKS} signoutButton={signoutButton}>
                <Component title={route.title} dense={true} secondary={true} />
              </Layout>
            </Route>
          );
        })}
        <Route path="/wechat">
          <Layout title="Wechat orders" drawerLinks={DRAWER_LINKS} signoutButton={signoutButton}>
            <ExternalOrders title="wechat orders" dense={true} secondary={true} />
          </Layout>
        </Route>
        <Route exact path="/">
          <Layout title="Home" drawerLinks={DRAWER_LINKS} signoutButton={signoutButton}>
            <Home actions={actions} user={data.user} />
          </Layout>
        </Route>
        <Route exact path="/change-password">
          <Layout title="Home" drawerLinks={DRAWER_LINKS} signoutButton={signoutButton}>
            <ChangePassword />
          </Layout>
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}
