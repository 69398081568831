import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Moment from 'react-moment';
import { loader } from 'graphql.macro';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DescriptionIcon from '@material-ui/icons/Description';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

// import List from '../Component/List';
import Link from '../Component/Link';
import LinkModal from '../Component/LinkModal';

const ORDER_EXPORT = loader('../Utils/Queries/ORDER_EXPORT.graphql');
const GENERATE_LINK = loader('../Utils/Mutation/GENERATE_LINK.graphql');

const OrderExport = () => {
  const [link, setLink] = useState();

  const { loading, error, data } = useQuery(ORDER_EXPORT);
  const [getPresignedLink] = useMutation(GENERATE_LINK, {
    onCompleted: (data) => {
      setLink(data.getPresignedLink);
    }
  });

  if (loading) return <p>Loading...</p>;
  if (error || !(data.getOrderLists)) return <p>Error :(</p>;

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">Order exports</Typography>
      </Breadcrumbs>
      {data.getOrderLists.Contents.map(({ Key, LastModified }) => (
        <ListItem button onClick={() => getPresignedLink({ variables: { Bucket: data.getOrderLists.Name, Key } })}>
          <ListItemAvatar><Avatar><DescriptionIcon /></Avatar></ListItemAvatar>
          <ListItemText
            primary={Key}
            secondary={<Moment format="YYYY-MM-DD HH:mm" date={Number(LastModified)} />}
          />
        </ListItem>
      ))}
      {link && <LinkModal link={link} closeModal={() => setLink(undefined)}/>}
    </Container>
  );
}

export default OrderExport;
