import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Input,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Chip,
  CircularProgress,
} from '@material-ui/core';

const GET_GROUPS = loader('../../Utils/Queries/GET_GROUPS.graphql');
const MODIFY_USER_GROUPS = loader('../../Utils/Mutation/MODIFY_USER_GROUPS.graphql');

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  }
}));


const EditGroupModal = ({ open, setOpen, groups, userId }) => {
  const classes = useStyles();
  const [newGroups, setNewGroups] = useState(groups);
  const [saving, setSaving] = useState(false);
  const { loading, data } = useQuery(GET_GROUPS);
  const [modifyUserGroups] = useMutation(MODIFY_USER_GROUPS)
  // TODO add mutation

  if (loading) return null;

  const updateGroups = async () => {
    setSaving(true);
    await modifyUserGroups({
      variables: {
        userId,
        groups: newGroups,
      }
    });
    setOpen(false);
  }

  return (
    <Dialog className={classes.dialog} onClose={() => setOpen(false)} open={open}>
      <DialogTitle>Edit Groups</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel id="group-label">Group</InputLabel>
          <Select
            labelId="group-label"
            multiple
            required
            onChange={(e) => setNewGroups(e.target.value)}
            value={newGroups}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={data.groups.find(g => g.id === value).description} className={classes.chip} />
                ))}
              </div>
            )}
          >
            {data.groups.map(group => (
              <MenuItem name={group.id} value={group.id}>{group.description}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateGroups}>{saving ? <CircularProgress /> : 'Update'}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditGroupModal;
