import React from 'react';
import Moment from 'react-moment';

import {
  Paper,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

import Link from '../Component/Link';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(4),
  }
}));

const UserTable = ({ users }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Groups</TableCell>
            <TableCell align="right">Created at</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">{user.email}</TableCell>
              <TableCell align="right">{user.status}</TableCell>
              <TableCell align="right">
                {user.groups.map(group => (
                    <Chip key={group.id} label={group.description} className={classes.chip} />
              ))}
              </TableCell>
              <TableCell align="right"><Moment date={new Date(Number(user.created))} /></TableCell>
              <TableCell align="right">
                <Link href={`/manage-users/${user.id}`}>View</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserTable;
