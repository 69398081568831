import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import ComponentWrapper from './components/ComponentWrapper';
import { loader } from 'graphql.macro';

const GET_PRODUCTS = loader('./GET_PRODUCTS.graphql');


function App() {
  const [country, setCountry] = useState('au');
  const { loading, data } = useQuery(GET_PRODUCTS, {
    variables: {
      country,
    }
  });

  if (loading && !data) {
    return 'please wait';
  }

  return (
    <div className="cart-builder">
      <ComponentWrapper products={data.getProducts} changeCountry={setCountry} />
    </div>
  );
}


export default App;
