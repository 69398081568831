import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { Button } from '@material-ui/core';
import AppRouter from './AppRouter';
import LoginScreen from './Page/LoginScreen';

import { Auth } from 'aws-amplify';

// TODO make this dynamic between envs

const getApolloClient = (Auth) => new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
  connectToDevTools: true,
  typeDefs: {}, // Added for Apollo Chrome tool
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  request: (operation) => {
    return Auth.currentSession().then(({ idToken }) => {
      operation.setContext({
      headers: {
        Authorization: idToken && idToken.jwtToken ? `Bearer ${idToken.jwtToken}` : ''
      }
    })
  }).catch(e => console.log(e));
  }
});

const App = ({ auth, signOut }) => {
  const apolloClient = getApolloClient(auth);

  return (
    <ApolloProvider client={apolloClient}>
      <AppRouter signoutButton={(
        <Button
          color="primary"
          onClick={signOut}
        >
          Sign Out
        </Button>
      )} />
    </ApolloProvider>
  );
}


const AppGateKeeper = (props) => {
  const [userState, setUserState] = useState('pending');

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(setUserState)
      .catch((e) => {
        setUserState(null);
        console.error(e);
      });
  }, []);


  const signOut = (async) => {
    Auth.signOut();
    setUserState(null);
  }

  if (userState === 'pending') {
    return (
      <div style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        Please wait...
      </div>
    );
  };

  if (userState) {
    return <App auth={Auth} signOut={signOut} />;
  }
  return <LoginScreen setUserState={setUserState} />;
}

export default AppGateKeeper;
