import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';

const Search = ({ add, productData }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);



    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleBlur = () => {
        setTimeout(function() {
            setSearchTerm('');
        }, 200);
    };

    useEffect(() => {
        const fuse = new Fuse(productData, {
            threshold: 0.4,
            keys: ['name', 'sku'],
        });

        const results = fuse.search(searchTerm);
        setSearchResults(results.map(res => res.item));
    }, [productData, searchTerm]);

    return (
        <div className="Search">
            <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
                onBlur={handleBlur}
            />


            <ul className={(!searchResults || searchResults.length === 0) ? 'EmptyList' : '' }>
                {searchTerm.length > 3 && searchResults.map(item => (
                    <li data-sku={item.sku} onClick={()=>add(item)}>{item.name}</li>
                ))}
            </ul>

        </div>
    );
};

export default Search;
