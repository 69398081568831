import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import '../style/App.scss';
import Search from './Search';
import GeneratedUrl from './GeneratedUrl';
import TextField from './TextField';
import SelectList from './SelectList';
import Cart from './Cart';
import CountryList from '../config/CountryList';
import Header from './Header';
import CnCSelector from './CnCSelector';

const GET_SELF = loader('../../../Utils/Queries/GET_SELF.graphql');

const ComponentWrapper = ({ products, changeCountry }) => {
  const [cart, setCart] = useState([]);
  const [consultantName, setConsultantName] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [CnCStore, setCnCStore] = useState('');
  const [redirectTo, setRedirectTo] = useState('checkout');
  const redirectOptions = ['checkout', 'home'];
  const [selectedCountry, setCountry] = useState('au');
  const [utmSource, setUtmSource] = useState('live_consultation');
  const isStaging = process.env.REACT_APP_ENV === 'staging';

  useQuery(GET_SELF, {
    onCompleted: ({ user }) => {
      setConsultantName(user.uuid)
    }
  });

  function addToCart(product) {
    window.gtag('send', 'event', 'cart-builder', 'add-item', product.sku);
    setCart([
      ...cart,
      product,
    ]);
  }


  function countryUpdate(country) {
    setCountry(country);
    changeCountry(country);
  }

  return (
    <div className="ComponentWrapper">
      <Header />
      <main>
        <div className="AdditionalFields">
          <SelectList
            selectedField={setUtmSource}
            options={['live_consultation', 'live_assistance', 'phone_call']}
            label="Conversation type"
            capitalize
          />
          <TextField name="Promo Code" fieldValue={setPromoCode} />
          <SelectList selectedField={setRedirectTo} options={redirectOptions} label="Redirect To" capitalize />
          {isStaging && <CnCSelector setSelectedStore={setCnCStore} selectedStore={CnCStore} country={selectedCountry} />}
        </div>
        <div className="SeachAndCart">
          <Cart cartItems={cart} />
          <SelectList selectedField={countryUpdate} options={CountryList} label="Country" />
          <Search add={addToCart} productData={products} />
        </div>
        <GeneratedUrl
          CnCStore={CnCStore}
          consultantName={consultantName}
          redirectTo={redirectTo}
          promoCode={promoCode}
          cartItems={cart}
          country={selectedCountry}
          utmSource={utmSource}
        />
      </main>
    </div>
  );
};

export default ComponentWrapper;
