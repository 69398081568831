import React from 'react';

const uppercase = string => string.charAt(0).toUpperCase() + string.slice(1);

const SelectList = ({ options, label, selectedField, capitalize }) =>  (
        <div className="SelectList">
            <label>{label}</label>
            <select
             onChange={(e)=>selectedField(e.target.value)}
            >
            {options.map((option, key) => (
                <option key={key} value={option}>{capitalize ? option.split('_').map(uppercase).join(' ') : option}</option>
                ))}

            </select>
            <div className="select-icon-wrapper">
                <svg className="Icon FormSelect-icon" role="img" viewBox="0 0 50 50"><g><polygon points="25,31.3 4.2,10.5 0.1,14.6 25,39.5 50,14.6 45.9,10.5 " /></g></svg>
            </div>
        </div>
    );


export default SelectList;
