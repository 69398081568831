import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import gel from 'aesop-gel';

import Link from '../../Component/Link';
import {
  TextField,
  Typography,
  // Breadcrumbs,
  Input,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Chip,
} from '@material-ui/core';

const { Button, Loading, Heading, Paragraph, TwoColumnLayout, Breadcrumbs } = gel.components;
const GET_GROUPS = loader('../../Utils/Queries/GET_GROUPS.graphql');
const ADD_USER = loader('../../Utils/Mutation/ADD_USER.graphql');

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(4),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  }
}));


const ListUsers = () => {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [email, setEmail] = useState('');
  const { loading, error, data } = useQuery(GET_GROUPS);
  const [addUser, { data: newUser, loading: addingUser, error: addUserError }] = useMutation(ADD_USER);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleChange = (e) => {
    setGroups(e.target.value);
  };

  const createUser = (e) => {
    e.preventDefault();
    addUser({
      variables: {
        email, groups
      }
    }).then((res) => {
      console.log(res);
      if (res) {
        setGroups([]);
        setEmail('');
      }
    });
  }

  return (

      <TwoColumnLayout
        isReversed={false}
        content={(
          <>
          <Breadcrumbs
            items={[
              {
                label: 'Home',
                id: 'home',
                url: '/',
                title: 'Home',
              },
              {
                label: 'Manage Users',
                id: 'manage-users',
                url: '/manage-users',
                title: 'Manage Users',
              },
              {
                label: 'Add User',
                id: 'add-user',
                url: '#',
                title: 'Add User',
              },
            ]}
          />
          <Heading level="1" size="xLarge" hasSerifFont={true}>
            Add user
          </Heading>
          {addUserError && (
            <Paragraph theme="dark">
              {addUserError.toString()}
            </Paragraph>
          )}
          {newUser && (
            <Paragraph theme="dark">
            Added new user {newUser.email}
            </Paragraph>
          )}
          <form>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="group-label">Group</InputLabel>
              <Select
                labelId="group-label"
                multiple
                required
                onChange={handleChange}
                value={groups}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={data.groups.find(g => g.id === value).description} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {data.groups.map(group => (
                  <MenuItem name={group.id} value={group.id}>{group.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              title="submit"
              type="submit"
              fullWidth
              isEnabled={!addingUser}
              disabled={addingUser}
              className={classes.submit}
              onClick={createUser}
              >
                {addingUser ? <Loading isLoading={true} theme="dark" /> : 'Create user'}
              </Button>
            </form>
            </>
        )}
      />
  );
}

export default ListUsers;
