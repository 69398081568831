import React from 'react'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const UserCard = ({ user }) => {
  return (
    <Card raised style={{ marginBottom: 20 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Welcome {user.email}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          Your details
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {user.groups && <><strong>Groups</strong>: {user.groups.map(g => g.description).join(', ')}<br /></>}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default UserCard;
