import React from 'react';

import {
  Switch,
  Route
} from "react-router-dom";

import ListUsers from './ListUsers';
import ManageUser from './ManageUser';
import AddUser from './UserManager/AddUser';

const UserManager = () => (
  <Switch>
    <Route exact path="/manage-users" component={ListUsers} />
    <Route exact path="/manage-users/add-user" component={AddUser} />
    <Route exact path="/manage-users/:userId" component={ManageUser} />
  </Switch>
);

export default UserManager;
