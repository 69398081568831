import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Checkbox } from '@material-ui/core';

const ImageLockupStyled = styled.div`
  padding: 10px;
  display: grid;
  justify-content: ${({after}) => after ? 'left' : 'right'};
  text-align: ${({after}) => after ? 'left' : 'right'};

  img {
    max-width: 100%;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
`;

const Container = styled.div`
  margin: 20px;
`;

const CompareStyled = styled(Grid)`
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #000;

  .diff {
    text-align: center;
  }
`;

const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

const ImageLockup = ({ url, size, desc, after, width, height }) => (
  <ImageLockupStyled after={after} >
    <img src={url} width={width} height={height} alt={desc} />
    <p>{desc}</p>
    <p>{size}</p>
  </ImageLockupStyled>
);

const BeforeAfter = ({ before, after, width, height }) => {
  const [beforeSize, setBeforeSize] = useState(before.size);
  const [afterSize, setAfterSize] = useState(after.size);
  const afterUrl = (width || height) ? `${after.url}&w=${width}` : after.url;

  useEffect(() => {
    fetch(before.url)
      .then(response => response.blob()).then(blob => setBeforeSize(blob.size));

    fetch(afterUrl)
      .then(response => response.blob()).then(blob => setAfterSize(blob.size));
  }, [before, after, afterUrl]);

  return (
    <CompareStyled container style={{ flexGrow: 1 }}>
      <Grid item xs={6}>
        <ImageLockup
          {...before}
          width={width}
          height={height}
          size={bytesToSize(beforeSize)}
        />
      </Grid>
      <Grid item xs={6}>
        <ImageLockup
          after
          {...after}
          url={afterUrl}
          size={bytesToSize(afterSize)}
        />
      </Grid>
      <Grid className="diff" item xs={12}>Difference: {(100 - (afterSize / beforeSize * 100)).toFixed(2)}% smaller</Grid>
    </CompareStyled>
  );
}

const JPGCompare = () => {
  const [webp, setWebp] = useState(true);
  const [imageurl, setImageurl] = useState('https://images.ctfassets.net/u1nb1km7t5q7/3LxXLZUllvOkEgMdWdcaOf/2a899f9a3f612f23640aa99605b4a671/Aesop-The-Paris-Review-Issue-227-Landing-Page-Hero-50-50-Tablet-1536x950px.jpg');

  const parsedUrl = `${imageurl}?${webp ?'fm=webp&' : ''}`

  return (
    <Container>
      <div style={{ padding: 10, marginBottom: 10 }}>
        <div>Use WebP
          <Checkbox
            checked={webp}
            onChange={(e) => setWebp(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
        <input
          style={{ display: 'block', width: '100%' }}
          value={imageurl}
          onChange={(e) => setImageurl(e.target.value)}
        />
      </div>

      <BeforeAfter
        before={{
          url: imageurl,
          desc: "Image from Contentful - Large Size",
        }}
        after={{
          url: `${parsedUrl}`,
          desc: `Image from Contentful - Large Size ${webp ? '(webp format)' : ''}`,
        }}
      />
      <BeforeAfter
        before={{
          url: imageurl,
          desc: "Image from Contentful - Large Size",
        }}
        after={{
          url: `${parsedUrl}q=90`,
          desc: `Image from Contentful - Large Size (${webp ? 'webp format, ' : ''}90% quality)`,
        }}
      />
      <BeforeAfter
        before={{
          url: imageurl,
          desc: "Image from Contentful - Large Size",
        }}
        after={{
          url: `${parsedUrl}q=80`,
          desc: `Image from Contentful - Large Size (${webp ? 'webp format, ' : ''}80% quality)`,
        }}
      />
      <BeforeAfter
        before={{
          url: imageurl,
          desc: "Image from Contentful - Large Size",
        }}
        after={{
          url: `${parsedUrl}q=70`,
          desc: `Image from Contentful - Large Size (${webp ? 'webp format, ' : ''}70% quality)`,
        }}
      />
      <BeforeAfter
        before={{
          url: imageurl,
          desc: "Image from Contentful - Large Size",
        }}
        after={{
          url: `${parsedUrl}q=60`,
          desc: `Image from Contentful - Large Size (${webp ? 'webp format, ' : ''}60% quality)`,
        }}
      />
      <BeforeAfter
        before={{
          url: imageurl,
          desc: "Image from Contentful - Large Size",
        }}
        after={{
          url: `${parsedUrl}q=10`,
          desc: `Image from Contentful - Large Size (${webp ? 'webp format, ' : ''}10% quality)`,
        }}
      />

      <hr />

      <BeforeAfter
        width={250}
        before={{
          url: imageurl,
          desc: "Image from Contentful - Large Size (browser resized to 250px)",
        }}
        after={{
          url: `${parsedUrl}q=90`,
          desc: `Image from Contentful - Large Size (${webp ? 'webp format, ' : ''}90% quality, resized to 250px)`,
        }}
      />
    </Container>
  );
}

const ImageOptimizeTest = () => <JPGCompare />;

export default ImageOptimizeTest;
