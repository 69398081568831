import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import gel from 'aesop-gel';
const { Button } = gel.components;

const GeneratedUrl = ({
    consultantName,
    redirectTo,
    promoCode,
    cartItems,
    country,
    utmSource,
    CnCStore,
}) => {
    let hostName = 'https://www.aesop.com/'
    if (process.env.REACT_APP_ENV === 'staging') {
        hostName = 'https://acceptance.aesop.com/'
    }

    const date = new Date()
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const utm_medium = "customer_service";
    const utm_campaign = `${consultantName}_${day}-${month}-${year}`;
    const urlStart = `${hostName}_ui/createCart.html?countryCode=${country}&`;
    const products = cartItems.map(product => (product.sku));
    const productString = products.length > 0 ? 'products=' + products : '';
    const consultantString = consultantName ? `&utm_source=${utmSource}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}` : "";
    const redirectString = redirectTo !== 'home' ? `&directTo=${redirectTo}` : '';
    const promoString = promoCode ? '&promoCode=' + promoCode : '';
    const cncString = CnCStore ? '&CnCStore=' + CnCStore.code : '';

    const url = urlStart
        + productString
        + consultantString
        + promoString
        + cncString
        + redirectString;

    const trackCopy = (text) => {
        window.gtag('send', 'event', 'cart-builder', 'link-copied', text);
    }

    return (
        <div className="GeneratedUrl">
            <div className="UrlBox">
                {products.length > 0 && url}
            </div>
            <CopyToClipboard
                text={url}
                onCopy={trackCopy}
            >
                <Button className="centered-button" isAlternate>Copy URL</Button>
            </CopyToClipboard>
        </div>
    );
};

export default GeneratedUrl;
