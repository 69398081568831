import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import Link from '../Component/Link';

const GET_BASTIONS = loader('../Utils/Queries/GET_BASTIONS.graphql');
const CONNECT_BASTION = loader('../Utils/Mutation/CONNECT_BASTION.graphql');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const BastionAccess = () => {
  const classes = useStyles();

  const [bastion, setBastion] = useState();
  const { loading, error, data } = useQuery(GET_BASTIONS);
  const [connectBastion, { data: connected, loading: connectLoading, error: connectError }] = useMutation(CONNECT_BASTION);

  if (loading) return <p>Loading...</p>;
  if (error || !(data.bastions)) return <p>Error :(</p>;

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Home
        </Link>
        <Typography color="textPrimary">Bastion Access</Typography>
      </Breadcrumbs>
      {data.bastions.map(({ environment }) => (
        <ListItem className={classes.root}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={clsx({
                [classes.buttonSuccess]: bastion === environment && connected && connected.connectBastion && connected.connectBastion.Connected,
              })}
              disabled={bastion === environment && connectLoading}
              onClick={() => {
                setBastion(environment);
                connectBastion({ variables: { environment: environment } });
              }}
            >
              {environment}
            </Button>
            {bastion === environment && connectLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
            <Typography color="textPrimary">
              {bastion === environment && connected && connected.connectBastion && connected.connectBastion.InstanceUser ? connected.connectBastion.InstanceUser : ""}
              {bastion === environment && connected && connected.connectBastion && connected.connectBastion.InstanceIp ? "@" + connected.connectBastion.InstanceIp : ""}
            </Typography>
        </ListItem>
      ))}
    </Container>
  );
}

export default BastionAccess;
