export default [
    'au',
    'nz',
    'us',
    'ca/en',
    'ca/fr',
    'uk',
    'fr/fr',
    'fr/en',
    'de/de',
    'de/en',
    'ch/en',
    'ch/de',
    'ch/fr',
    'it',
    'hk/en',
    'hk/zh_cn',
    'hk/zh_hant',
    'jp',
    'kr',
    'tw',
    'sg',
    'se',
    'no',
    'dk',
];
