import React from 'react';

const TextField = ({ name, value, fieldValue }) => {
    return (
        <>
            <div className="TextField">
                <h3>Please note: the generated URL will expire in 3 days.</h3> 
            </div>
            <div className="TextField">
                <label htmlFor={name} className="TextFieldLabel">{name}</label>
                <input type="text" name={name} onChange={(e) => fieldValue(e.target.value)} value={value} />
            </div>
        </>
    );
};

export default TextField;
