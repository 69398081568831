import React from 'react';
import Container from '@material-ui/core/Container';


function NotFound() {
  return (
    <Container  maxWidth="sm">
      <b>Empty page! Looks like you took wrong turn!</b>
    </Container>
  );
}

export default NotFound;
