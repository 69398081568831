import React from 'react';
import ComponentWrapper from './components/ComponentWrapper';



function App() {
  return (
    <div className="url-shortener">
      <ComponentWrapper />
    </div>
  );
}


export default App;
