import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import gel from 'aesop-gel';

import { Paper, Grid, Typography, CssBaseline, TextField, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AesopLogo from '../Component/AesopLogo';

const { Button, Loading } = gel.components;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://www.aesop.com/medias/Aesop-Rozu-Campaign-Hybris-Homepage-Primary-50-50-Desktop-1440x1500px.jpg?context=bWFzdGVyfGltYWdlc3wyMzUyMDF8aW1hZ2UvanBlZ3xpbWFnZXMvaDcxL2hmOC85MzE1OTIwNTQzNzc0LmpwZ3xmZTE4NjE4YmViN2M5YjIzNzk3NTk5ODg0YWRmNzEzMWI3YjZiMDkzMTcxMzM2MDVkZWRlYjI5Y2I0NjYxZmZi)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#643e43',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
    maxWidth: 'none',
    minHeight: 57,
  },
}));

const SignIn = ({ setUserState, setTempUser, setScreen }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState();

  const classes = useStyles();

  const resetPassword = () => {
    setScreen('reset-password');
  }

  const handleSignIn = async (e) => {
    setLoading(true);
    e.preventDefault();
    Auth.signIn(email, password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setTempUser(user);
          setScreen('update-password')
        } else {
          setUserState(user);
        }
      })
      .catch((e) => {
        setLoading(false);
        if (typeof e === 'object') {
          setError(e.message);
        } else {
          setError(e.toString().replace('AuthError: ', ''));
        }
      });
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <AesopLogo width={300} />
          <form className={classes.form} noValidate>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Link onClick={resetPassword}>Reset Password</Link>
            <Grid>
              <Button
                title="submit"
                type="submit"
                fullWidth
                isAlternate
                isEnabled={!loading}
                disabled={loading}
                className={classes.submit}
                onClick={handleSignIn}
              >
                {loading ? <Loading isLoading={true} theme="light" /> : 'Sign In'}
              </Button>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default SignIn;
