import React, { useState } from 'react';
import { Grid, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SignIn from './SignIn';
import UpdatePassword from './UpdatePassword';
import ResetPassword from './ResetPassword';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://www.aesop.com/medias/Aesop-Rozu-Campaign-Hybris-Homepage-Primary-50-50-Desktop-1440x1500px.jpg?context=bWFzdGVyfGltYWdlc3wyMzUyMDF8aW1hZ2UvanBlZ3xpbWFnZXMvaDcxL2hmOC85MzE1OTIwNTQzNzc0LmpwZ3xmZTE4NjE4YmViN2M5YjIzNzk3NTk5ODg0YWRmNzEzMWI3YjZiMDkzMTcxMzM2MDVkZWRlYjI5Y2I0NjYxZmZi)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const SignInSide = ({ setUserState }) => {
  const [screen, setScreen] = useState('signin');
  const [tempUser, setTempUser] = useState(null);

  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      {screen === 'signin' && <SignIn setUserState={setUserState} setTempUser={setTempUser} setScreen={setScreen} />}
      {screen === 'update-password' && <UpdatePassword tempUser={tempUser} setUserState={setUserState} />}
      {screen === 'reset-password' && <ResetPassword setUserState={setUserState} setScreen={setScreen} />}
    </Grid>
  );
}

export default SignInSide;
