import React from 'react';

const Cart = ({ cartItems }) => (
    <section className="Cart">
        <h2>Cart</h2>
        {cartItems.length === 0 &&
            <span className="EmptyCart">
                <i>Empty, please search and add products to cart</i>
            </span>
        }
        <ul>
            {cartItems && cartItems.map(item=>(
                <li>{item.name}</li>
            ))}
        </ul>
    </section>
);
export default Cart;