import React from 'react';
import Moment from 'react-moment';

import Card from '@material-ui/core/Card';
import Modal from '@material-ui/core/Modal';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const InnerModal = ({ link, closeModal }) => {
  const downloadLink = () => {
    window.open(link.url);
  }
  return (
    <Modal
      open={true}
      onClose={closeModal}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Your link has been generated
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            It will expire <Moment fromNow date={new Date().getTime() + (link.expiration * 1000)} />
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={downloadLink}>
            Download now
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

export default InnerModal;
