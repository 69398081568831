import React, { useState } from 'react';

import '../style/App.scss';
import GeneratedUrl from './GeneratedUrl';
import TextField from './TextField';
import Header from './Header';


const ComponentWrapper = () => {
  const [redirectValue, setRedirectValue] = useState();

  return (
    <div className="ComponentWrapper">
      <Header />
      <main>
        <div className="AdditionalFields">
          <TextField name="URL" fieldValue={setRedirectValue}/>
        </div>
        <GeneratedUrl
          redirect={redirectValue}
        />
      </main>
    </div>
  );
};

export default ComponentWrapper;
